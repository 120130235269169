import { mapActions, mapState } from 'vuex';
import { prepareCategoryProduct } from 'theme/helpers';
import { getMoneyFormat } from 'theme/helpers/orders';
import { isGift } from 'theme/helpers/gift';
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';

export default {
  data () {
    return {
      existingOrderProducts: []
    }
  },
  computed: {
    ...mapState({
      currentShipping: state => state['shipping-module'].current,
      loadedShipping: state => state['shipping-module'].loaded.shipping
    }),
    shippingChangeTrigger () {
      return {
        shopId: this.currentShipping?.shop?.id,
        method: this.currentShipping?.method,
        newPostRef: this.currentShipping?.npShop?.ref,
        loaded: this.loadedShipping
      }
    },
    orderItems () {
      return this.order.items?.filter(e => !isGift(e))
    },
    producsIds () {
      if (!this.order || !this.orderItems) return []
      return this.orderItems.map(e => e.product_id) || []
    },
    orderProducts () {
      return [ ...this.existingProducts, ...this.nonExistingProducts ]
    },
    existingProducts () {
      const products = this.existingOrderProducts.map(prepareCategoryProduct) || []
      return this.getProductsWithOrderData(products, this.orderItems) || []
    },
    nonExistingProducts () {
      const existSku = this.existingProducts.map(e => e.sku) || []
      const products = this.orderItems || []
      const nonExistOrderProducts = products.filter(e => !existSku.includes(e.sku)) || []

      const result = []

      nonExistOrderProducts.forEach(e => {
        result.push({
          sku: '',
          link: '',
          title: e.name,
          quantityText: '',
          qty: e.qty,
          price: { regular: getMoneyFormat(e.price || '') },
          total: getMoneyFormat(e.total || ''),
          productExist: false
        })
      })

      return result || []
    }
  },
  watch: {
    shippingChangeTrigger: {
      handler: async function (newValue, oldValue) {
        if (
          !newValue?.loaded || (
            newValue?.shopId && (
              newValue?.shopId === oldValue?.shopId &&
              newValue?.method === oldValue?.method &&
              newValue?.newPostRef === oldValue?.newPostRef &&
              newValue?.loaded === oldValue?.loaded
            )
          )
        ) return

        this.getExistingOrderProducts()
      }
    }
  },
  methods: {
    ...mapActions({
      getProductsById: 'product/getProductsByID'
    }),
    getProductsWithOrderData (products = [], orderItems = []) {
      return [...(orderItems || [])].map(e => {
        let skuInfo = products.find(item => isSkuEqual(item, e)) || {}
        let removeMarked = false

        if (!skuInfo?.sku) {
          removeMarked = true

          skuInfo = products.find(item => isSkuEqual({ sku: item.sku }, { sku: e.sku })) || {}
        }

        if (!skuInfo?.sku) return null

        const result = {
          ...skuInfo,
          currentPrice: e.price,
          price: {
            regular: getMoneyFormat(e.price || ''),
            special: ''
          },
          total: getMoneyFormat(e.total || ''),
          qty: e.qty || '',
          productExist: true
        }

        if (removeMarked) delete result.markdown_id

        return result
      }).filter(i => !!i)
    },
    async getExistingOrderProducts () {
      if (this.producsIds.length) {
        this.existingOrderProducts = await this.getProductsById({
          ids: this.producsIds,
          loadDiscountedProducts: true
        })
      }
    }
  }
}
