<template>
  <div class="m-account-order">
    <MAccountOrderHeader
      class="account-order-header"
      :order="order"
      @closeOrder="closeOrder"
    />
    <div v-if="isDesktop" class="account-order-main">
      <div class="order">
        <MAccountOrderFiscal
          :existing-products="existingProducts"
          :order="order"
          class="order-receipt"
        />
        <MAccountOrderDetails
          :order="order"
          :is-new-post="isNewPost"
          class="order-details"
        />
        <MAccountOrderCheque
          :existing-products="existingProducts"
          :is-new-post="isNewPost"
          :order="order"
          class="order-receipt"
        />
        <MAccountOrderCancel
          :order="order"
          :products="orderProducts"
        />
      </div>
      <div class="products">
        <MAccountOrderProducts
          v-if="orderProducts.length"
          :products="orderProducts"
          :order-type="order.type"
          class="order-products"
        />
      </div>
    </div>
    <div v-if="isMobile || isTablet" class="account-order-main">
      <MAccountOrderFiscal
        :existing-products="existingProducts"
        :order="order"
        class="order-receipt"
      />
      <MAccountOrderProducts
        v-if="orderProducts.length"
        :products="orderProducts"
        :order-type="order.type"
        class="order-products"
      />
      <MAccountOrderCancel
        :order="order"
        :products="orderProducts"
      />
      <MAccountOrderDetails
        :order="order"
        :is-new-post="isNewPost"
        class="order-details"
      />
      <MAccountOrderCheque
        :existing-products="existingProducts"
        :is-new-post="isNewPost"
        :order="order"
        class="order-receipt"
      />
    </div>
  </div>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import MAccountOrderHeader from 'theme/components/molecules/m-account-order-header';
import MAccountOrderFiscal from 'theme/components/molecules/m-account-order-fiscal';
import MAccountOrderCheque from 'theme/components/molecules/m-account-order-cheque';
import MAccountOrderDetails from 'theme/components/molecules/m-account-order-details';
import MAccountOrderProducts from 'theme/components/molecules/m-account-order-products';
import MAccountOrderCancel from 'theme/components/molecules/m-account-order-cancel';
import { isServer } from '@vue-storefront/core/helpers'
import { createSmoothscroll } from 'theme/helpers'
import OrderProducts from 'theme/mixins/OrderProducts';
import GoogleTagManager from 'theme/mixins/gtm'
import { newPostMethods } from '$modules/shipping/config';

export default {
  name: 'MAccountOrder',
  components: {
    MAccountOrderHeader,
    MAccountOrderCheque,
    MAccountOrderDetails,
    MAccountOrderProducts,
    MAccountOrderCancel,
    MAccountOrderFiscal
  },
  mixins: [DeviceType, OrderProducts, GoogleTagManager],
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    closeOrder () {
      this.$emit('closeOrder')
    }
  },
  computed: {
    isNewPost () {
      return newPostMethods.includes(this.order?.delivery_method?.toUpperCase())
    }
  },
  async mounted () {
    if (!isServer) {
      createSmoothscroll((document.documentElement.scrollTop || document.body.scrollTop), -20);
      this.admitadOrderPage()
    }
    this.getExistingOrderProducts()
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";

.m-account-order {
  display: flex;
  flex-direction: column;
}

.account-order-main {
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile-max) {
    margin-top: var(--spacer-25);
    margin-bottom: var(--spacer-15);

    .order-products {
      margin-top: var(--spacer-25);
    }
  }

  @include for-tablet {
    padding: var(--spacer-30);
  }

  @include for-desktop {
    flex-direction: row;

    .order {
      padding: var(--spacer-50);
      flex-basis: 50%;
      border-right: 1px solid var(--gray3);
    }

    .products {
      padding: var(--spacer-50) var(--spacer-40);
      flex-basis: 50%;
    }
  }
}
</style>
